import React from "react"
import { BlogPostType } from "../components/blog/blogPostSection"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import BlogWrapper from "../components/blog/index"
import { graphql, useStaticQuery } from "gatsby"
import Wave, { WaveType } from "../components/ui/wave"
import { Locale } from "../common/appContext"

const Blog = ({path}) => {
  const { allGraphCmsBlogPost } = useStaticQuery(graphql`
    {
      allGraphCmsBlogPost(
        sort: { order: DESC, fields: createdAt }
        filter: {
          blogCategories: { elemMatch: { visible: { eq: true } } }
          locale: { eq: sv }
        }
      ) {
        nodes {
          blogCategories {
            category
            color
            slug
          }
          body {
            html
          }
          heading
          summary
          slug
          picture {
            alt
            id
            url
            localFile {
              childrenImageSharp {
                gatsbyImageData(width: 596, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)

  const posts: BlogPostType[] = allGraphCmsBlogPost.nodes

  return (
    <Layout locale={Locale.sv} footerClassName="u-bg-grey" path={path}>
      <SEO title="Blogg" />
      <BlogWrapper category={"all"} posts={posts} />
      <Wave className={"u-c-grey"} type={WaveType.v1} />
    </Layout>
  )
}

export default Blog
